<template>
  <click-to-edit
    v-model="scope.row.scale.status"
    placeholder="Выберите статус"
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.status')"
    type="select"
    select-filterable
    :select-options="[
      {id: 1, name:'Не начато'},
      {id: 2, name:'В процессе'},
      {id: 3, name:'Выполнено'},
    ]"
    select-option-id="id"
    select-option-name="name"
    select-cast-value-to-int
    @input="saveElementPropChange(scope.row, 'scale', {status:$event})"
  >
  </click-to-edit>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-status-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {

    }
  },

  methods: {

  }
}

</script>

<style>

</style>
